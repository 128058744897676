<!-- banner管理 -->
<template>
  <div>
    <el-card style="margin-top: 20px">
      <div class="input-with">
        <el-button type="primary" @click="addbanner()" :disabled="addBtnDisabled">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="listData"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="序号" align="center" prop="id">
          <template v-slot="{ row }">
            <div>G000{{ row.id }}</div>
          </template>
        </el-table-column>

        <el-table-column label="名称" prop="imgName" align="center"></el-table-column>
        <el-table-column label="banner" align="center">
          <template v-slot="{ row }">
            <div>
              <el-image
                style="width: 100px; height: 50px"
                :preview-src-list="[row.imgUrl]"
                :src="row.imgUrl"
                alt=""
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="链接" prop="link" align="center">
          <template v-slot="{ row }">
            <span>{{ row.link }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <span class="edit" @click="editBtn(row.id)">编辑</span>
            <span class="frozen" v-if="row.isDelete==0" @click="deleteBtn(row.id)">冻结</span>
            <span class="frozen" v-if="row.isDelete==1" @click="deleteBtns(row.id)">激活</span>
            <!-- <span v-if="row.isDelete != 1" class="frozen" @click="carouselDelete(row)">冻结</span>
            <span v-if="row.isDelete == 1" class="frozen" @click="carouselDelete(row)">激活</span> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>

    <!-- 新增弹窗 -->
    <el-dialog
      title="新增"
      :visible.sync="DialogVisible"
      width="60%"
      class="account_dialog"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="form">
        <el-form-item label="名称:" prop="imgName">
          <div class="large_input">
            <el-input v-model="adminUser.imgName" placeholder="请输入名称" />
          </div>
        </el-form-item>
        <el-form-item label="banner:" prop="imgUrl">
          <img-big-upload
            :imageShow.sync="adminUser.imgUrl"
            :addTitleShow="false"
            @removeImg="removeImgDian"
            @uploadOneImgShow="uploadOneImgShow"
          />
        </el-form-item>
        <span style="font-size: 14px; color: #999999; position: relative; left: 100px; top: -10px"
          >建议上传图片整体尺寸为358*180 px，大小不超过8MB</span
        >
        <el-form-item label="链接:" prop="link">
          <div class="large_input">
            <el-input
              v-model="adminUser.link"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入对应的链接ID，例如：/pages/activitydetails/activitydetails?id=87"
              show-word-limit
            />
          </div>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :disabled="submitBtnDisabled"
          >确 定</el-button
        >
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  bannerList,
  bannerListById,
  bannerSave,
  bannerUpdate,
  bannerDeleteById,
  carouselActivate
} from '@/api/system'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10
})
const defaultAdminUser = Object.freeze({
  id: null,
  imgUrl: '',
  imgName: '',
  link: ''
})
export default {
  name: 'Banner',
  data() {
    return {
      addBtnDisabled: false,
      submitBtnDisabled: false,
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      DialogVisible: false,
      adminUser: { ...defaultAdminUser },
      listrole: [],
      adminrules: {
        imgName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        imgUrl: [{ required: true, message: '请上传banner', trigger: 'blur' }],
        link: [{ required: true, message: '请输入链接', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const res = await bannerList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.warning('查询失败!')
        this.loading = false
      }
    },
    //新增banner
    addbanner() {
      this.addBtnDisabled = true
      setTimeout(() => {
        this.addBtnDisabled = false
      }, 1000)
      this.DialogVisible = true
      this.adminUser = { ...defaultAdminUser }
    },
    //编辑
    editBtn(id) {
      this.bannerListById(id)
      this.DialogVisible = true
    },
    //banner详情
    async bannerListById(id) {
      const res = await bannerListById({ id })
      if (res.resultCode == 200) {
        this.adminUser = res.data
      }
    },
    deleteBtn(id) {
      this.$confirm('确认是否冻结，冻结后不可撤回')
        .then(async () => {
          const res = await bannerDeleteById({ id })
          if (res.resultCode == 200) {
            this.$message.success('冻结成功!')
            this.search()
          } else {
            this.$message.warning('冻结失败!')
          }
        })
        .catch(() => {})
    },
    deleteBtns(id){
      this.$confirm('确认是否激活，激活后不可撤回')
        .then(async () => {
          const res = await carouselActivate({ id })
          if (res.resultCode == 200) {
            this.$message.success('激活成功!')
            this.search()
          } else {
            this.$message.warning('激活失败!')
          }
        })
        .catch(() => {})
    },
    //保存banner
    submitForm() {
      this.submitBtnDisabled = true
      setTimeout(() => {
        this.submitBtnDisabled = false
      }, 1000)
      // console.log(this.adminUser)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            // 没有id为新增
            bannerSave(this.adminUser).then((response) => {
              if (response.resultCode === 200) {
                this.$message.success('新增成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.warning(response.message)
              }
            })
          } else {
            bannerUpdate(this.adminUser).then((response) => {
              if (response.resultCode === 200) {
                this.$message.success('编辑成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.warning(response.message)
              }
            })
          }
        }
      })
    },
    // 删除图
    removeImgDian(val) {
      this.adminUser.imgUrl = ''
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.adminUser.imgUrl = value
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
} 
</script>

<style lang="less" scoped>
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 20px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.large_input {
  width: 80%;
}
</style>
